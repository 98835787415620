import { Tag, Text } from "@fscrypto/ui";
import clsx from "clsx";
import { HeartIcon } from "lucide-react";
import { tracking } from "~/utils/tracking";
import type { DashboardLikesButtonProps } from "./dashboard-likes";

const LikeButton = ({ onClick, likeCount, likedByMe, variant }: DashboardLikesButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    tracking(`click_like_dashboard`, "Dashboard Editor");
    onClick(e);
  };
  return (
    <Tag
      onClick={handleClick}
      variant="outlined"
      size="sm"
      data-testid="like-button"
      aria-label="like-button"
      className="cursor-pointer"
      prefix={
        <HeartIcon
          className={clsx("h4 w-4", {
            "fill-red-50 text-red-50": likedByMe,
            "text-gray-60 dark:text-gray-30": !likedByMe,
          })}
        />
      }
    >
      <Text className="dark:text-white">{(likeCount ?? 0) > 0 ? likeCount : null}</Text>
    </Tag>
  );
};

export default LikeButton;
